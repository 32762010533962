const saveDashboardList = (updateQSList, { observer, biRepo }) => {
  biRepo.saveDashboard(updateQSList, {
    success: ({data, errors}) => {
      if (errors || data.error) {
        observer.failure(errors || data.error)
        return
      }
      observer.success(data.createOrUpdateDashboard)
    },
    failure: (e) => {
      observer.failure(e)
    }
  })
}

export default saveDashboardList
