import {Alert, Button, Form, InputGroup} from 'react-bootstrap'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { NavLink, useHistory } from 'react-router-dom'

import loginUserUseCase from '../use-cases/login-user-use-case'
import Routes from '../../routes'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import Roles from 'config/Roles'

const UsernamePassword = ({
  username,
  setUsername,
  setChallengePasswordSession,
  setChangePasswordChallenge,
  repoFactory
}) => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [password, setPassword] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory()

  const handleLogin = (e) => {
    e.preventDefault()
    setIsLoading(true)
    loginUserUseCase(
      {
        username,
        password
      },
      {
        authRepo: repoFactory.authRepo(),
        observer: {
          loginSuccess: ({ authToken, refreshToken, expires, role }) => {
            setError(false)
            localStorage.setItem('username', username)
            localStorage.setItem('authToken', authToken)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('expirationTime', JSON.stringify(moment.now() + expires * 1000))
            localStorage.setItem('roles', JSON.stringify([role]))
            setChangePasswordChallenge(false)
            setIsLoading(false)
            if (role === Roles.ADMINS) {
              history.push(Routes.ACCOUNT.PATH)
            } else {
              history.push(Routes.DASHBOARD.PATH)
            }
          },
          loginChangePasswordChallenge: (session) => {
            setError(false)
            setChallengePasswordSession(session)
            setIsLoading(false)
            setChangePasswordChallenge(true)
          },
          loginFailed: () => {
            setChangePasswordChallenge(false)
            setIsLoading(false)
            setError(true)
          }
        }
      }
    )
  }

  return (
    <>
      {error && <Alert variant={'danger'}>Unable to login, please check your credentials and try again</Alert>}
      {isLoading && <LoadingSpinner />}
      <Form>
        <Form.Group className="mb-3" controlId="formBasicUsername">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="username"
            placeholder="Enter username"
            onChange={(e) => {
              setUsername(e.target.value)
            }}
          />
        </Form.Group>

        <Form.Group className="mb-4" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <InputGroup>
            <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
            />
            <InputGroup.Text
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <div className="d-flex justify-content-between align-items-center">
          <NavLink to={{ pathname: Routes.FORGOT_PASSWORD.PATH, state: { value: username } }} className="text-sm">
            Forgot password?
          </NavLink>
          <Button variant="primary" type="submit" disabled={!(username && password) || isLoading} onClick={handleLogin}>
            Sign In
          </Button>
        </div>
      </Form>
    </>
  )
}

export default UsernamePassword
